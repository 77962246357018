import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import React from "react";
import { Trans } from "react-i18next";
import tilt_border_footer from "../../../assets/tilt_border_footer.png";
import "../layout.css";

export default () => {
  return (
    <div style={{ overflow: `hidden` }}>
      <div
        style={{
          marginBottom: `-7.5%`,
          position: `relative`,
          zIndex: 5
        }}
      >
        <img
          className="container-footer"
          alt="Top Border"
          //   style={{ width: `100%`, animation: `neutral 8s forwards` }}
          src={tilt_border_footer}
        />
      </div>
      <div className="background-homepage2">
        <div className="homepage2">
          <p className="homepage2-p-header">
            <Trans i18nKey="18 years of experience" />
            {"\n"}
            <span className="colored-p">
              <Trans i18nKey="at your service" />
            </span>{" "}
            .
          </p>
          <p className="homepage2-p">
            {
              <span className="colored-p-lower">
                <Trans i18nKey="Established on 4 continents and 20 countries," />
              </span>
            }
            <Trans i18nKey="we favor the long-term partnership with our customers, to promote your brand image optimally." />
          </p>
        </div>
      </div>
    </div>
  );
};
