import React from "react";
import tilt_border from "../../../assets/tilt_border.png";
import Excerpt from "../excerpt";

export default ({
  className,
  excerpt,
  highlight,
  excerptEnd,
  scrollId
}: {
  className?: string;
  excerpt?: string;
  highlight?: string;
  excerptEnd?: string;
  scrollId?: string;
}) => {
  return (
    <div>
      <div
        className={className}
        style={
          typeof window !== "undefined"
            ? { height: window.innerHeight }
            : { display: `block` }
        }
      >
        <Excerpt
          excerpt={excerpt}
          excerptEnd={excerptEnd}
          highlight={highlight}
          scrollId={scrollId}
          style={{ animation: `none`, bottom: `unset`, top: `280px` }}
        />
        <div className="border-margin">
          <a
            id="scrollUpper"
            href={"#scrollUpper"}
            className={scrollId ? `scrollid` : `scrollid-hide`}
          >
            {" "}
            <img src="https://www.surya-digital.com/wp-content/uploads/2017/10/arrow-button-1.png" />
          </a>
          <img
            className="container-header"
            alt="Bottom Border"
            style={{ animation: `neutral 8s forwards` }}
            src={tilt_border}
          />
        </div>
      </div>
    </div>
  );
};
