import React from "react"
import Homepage1 from "../components/homepage/homepage-1"
import Homepage2 from "../components/homepage/homepage-2"
import Layout from "../components/layout"
import "../components/layout.css"

class Homepage extends React.Component {
    public render() {
        return (
            <Layout>
                <Homepage1 />
                <Homepage2 />
            </Layout>
        )
    }
}

export default Homepage
