import React from "react";
import { useTranslation } from "react-i18next";
import FeatureContainerThree from "../featureContainerThree";

export default () => {
  const { t } = useTranslation();
  return (
    <div className="three-features-grid">
      <FeatureContainerThree
        excerpt={t("Speed ​​up your internal process.")}
        headerIconSource={require(`../../../assets/watch_icon.png`)}
      />
      <FeatureContainerThree
        excerpt={t("We directly integrate your release development teams.")}
        // style={{ width: `40px` }}
        headerIconSource={require(`../../../assets/icon5.png`)}
      />
      <FeatureContainerThree
        excerpt={t(
          "Our proven test methodologies, list the malfunctions of your sites and applications, to establish a quick and adapted response."
        )}
        // style={{ width: `45px` }}
        headerIconSource={require(`../../../assets/icon6.png`)}
      />
    </div>
  );
};
