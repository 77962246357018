import { graphql, navigate, useStaticQuery } from "gatsby";
import React from "react";
// import { Trans } from "@lingui/macro";
import { Trans } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import Container from "../container";
import FeatureContainerFour from "../featureContainerFour";
import FeatureContainerThree from "../featureContainerThree";
import "../layout.css";
import HomepageFourFeatures from "./homepageFourFeatures";
import HomepageImageContainerWithHeaderOnly from "./homepageImageContainer";
import HomepageThreeFeatures from "./homepageThreeFeatures";

class Homepage1 extends React.Component {
  public render() {
    return (
      <div>
        <HomepageImageContainerWithHeaderOnly
          className="background-homepage1"
          excerpt="Adapt your Information Technologies (IT) to the"
          highlight="vision of your brand."
          scrollId="#homepage1"
        />
        <Container>
          <div id="homepage1">
            <img
              src="https://www.surya-digital.com/wp-content/uploads/2017/10/slall-heading.png"
              alt="Header Logo"
            />
            <h2 style={{ margin: `0px`, fontWeight: 200, fontSize: 31 }}>
              <Trans i18nKey="accelerate your growth" />
            </h2>
          </div>
          <div className="feature-container-div">
            <div>
              <HomepageFourFeatures />
            </div>
            <div className="homepage1-button1">
              <button
                className="button"
                onClick={() => {
                  navigate("/services/");
                }}
                style={{ marginRight: `auto` }}
              >
                <Trans i18nKey="SEE OUR SERVICES >>" />
              </button>
            </div>
          </div>
          <p className="homepage1-header-mid">
            <Trans i18nKey="We establish together, centers of competence, dedicated to the" />{" "}
            {
              <span className="colored-p">
                {" "}
                <Trans i18nKey="optimization of your Information Systems" />{" "}
              </span>
            }{" "}
            <Trans i18nKey="(IS)." />
          </p>
          <h2 className="homepage1-concentrate">
            <Trans i18nKey="Concentrate" />
            <span style={{ display: `block` }}>
              <Trans i18nKey="on" />{" "}
              {
                <p className="colored-p">
                  <Trans i18nKey="your know how" />
                </p>
              }
            </span>
          </h2>
          <div className="feature-container-div">
            <div>
              <HomepageThreeFeatures />
            </div>
            <div className="homepage1-button2">
              <button
                className="button"
                onClick={() => {
                  navigate("/references/");
                }}
              >
                <Trans i18nKey="SEE OUR REFERENCES >>" />
              </button>
            </div>
          </div>
          <p className="services-header-lower">
            <Trans i18nKey="Unobstructed from these tasks," />{" "}
            {
              <span className="colored-p">
                <Trans i18nKey="your designers can focus on innovation." />
              </span>
            }
          </p>
        </Container>
      </div>
    );
  }
}

export default Homepage1;
