import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import FeatureContainerFour from "../featureContainerFour";

export default () => {
  const { t } = useTranslation();
  return (
    <div className="four-features-grid">
      <FeatureContainerFour
        excerpt={t(
          "Benefit from the experience of the greatest data scientists."
        )}
        // style={{ width: `40px` }}
        headerIconSource={require(`../../../assets/icon1.png`)}
      />
      <FeatureContainerFour
        excerpt={t("Give your customers the best use of your digital tools.")}
        // style={{ width: `40px` }}
        headerIconSource={require(`../../../assets/icon2.png`)}
      />
      <FeatureContainerFour
        excerpt={t("Coordinate the IT management devices of your business.")}
        // style={{ height: `50px`, width: `58.7px` }}
        headerIconSource={require(`../../../assets/icon3.png`)}
      />
      <FeatureContainerFour
        excerpt={t("And increase your value creation.")}
        // style={{ width: `63px` }}
        headerIconSource={require(`../../../assets/icon4.png`)}
      />
    </div>
  );
};
